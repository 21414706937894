const workersCompV2 = {
  namespaced: true,
  getters: {
    getQualified: (state, getters, rootState) => {
      return (
        rootState.tortData.injury_date_over_two_years === "no" &&
        rootState.tortData.did_you_see_a_doctor === "yes" &&
        rootState.tortData.injury_occur_while_working === "yes" &&
        rootState.fields.hasLawyer === "no" &&
        rootState.fields.isGibberish === "no" &&
        rootState.fields.enoughWords === "yes" &&
        rootState.fields.hasBadWords === "no" &&
        rootState.fields.name_bad_word === "no" &&
        rootState.fields.name_gibberish === "no"
      )
    }
  },
  actions: {
    goToNextStep({rootState, getters, commit, dispatch}, step) {
      /* Step Order
      * DoesUserKnowWorkPlaceInjuryWorth
      * InjuredOrDevelopedIllnessWithin2Years
      * DidInjuryOccurWhileWorking
      * DidYouSeeDoctorForInjuryAtWork
      * SelectDate
      * HasLawyer
      * CaseDescription
      * EmailAndNameInformation
      * ZipAndPhone */

      // scroll to top of page
      window.scrollTo({top: 0, behavior: 'smooth'})

      let nextStep = ''
      let progress = 0
      const path = window.location.pathname

      // if (rootState.DQ.isDisqualified) {
      //   commit('SET_CURRENT_STEP', 'TapStoneDQ', { root: true })
      //   dispatch('goToDisqualifyThankYouPage')
      //   return
      // }

      switch(step) {
        case 'StartPath': {
          if (path === '/workers-comp-vw' || path === '/workers-comp-vw/') {
            nextStep = 'DoesUserKnowWorkPlaceInjuryWorthVWOVersion'
            progress = 12
            break
          }
          if (path === '/workers-comp-vw2' || path === '/workers-comp-vw2/') {
            nextStep = 'DoesUserKnowWorkPlaceInjuryWorthVWOVersionV2'
            progress = 12
            break
          }
          if (path === '/workers-comp-vw3' || path === '/workers-comp-vw3/') {
            nextStep = 'InjuredOrDevelopedIllnessWithin2YearsCROVersionV3'
            progress = 18
            break
          }
          nextStep = 'TypeOfAccident'
          progress = 12
          break
        }

        case 'TypeOfAccident':
          if (rootState.tortData.type_of_accident === 'Car Accident') {
            nextStep = 'WhenWasTheAccident'
            progress = 15
            break
          }

          nextStep = 'SelectDateV2'
          progress = 18
          break

        case 'WhenWasTheAccident':
          nextStep = 'AtFault'
          progress = 24
          break

        case 'AtFault':
          nextStep = 'WasUserInjured'
          progress = 38
          break

        case 'WasUserInjured':
          if (rootState.tortData.specific_injury === 'No Injury') {
            nextStep = 'PoliceReport'
            progress = 60
            break
          }

          nextStep = 'DidYouReceiveMedicalTreatment'
          progress = 48
          break

        case 'DidYouReceiveMedicalTreatment':
          nextStep = 'PoliceReport'
          progress = 60
          break

        case 'PoliceReport':
          nextStep = 'HasLawyerMVA'
          progress = 72
          break

        case 'HasLawyerMVA':
          nextStep = 'CaseDescriptionV3'
          progress = 78
          break

        case 'CaseDescriptionV3':
          nextStep = 'EmailAndNameInformation'
          progress = 84
          break

        case 'FirstStepZipAndPhone':
          nextStep = 'InjuredOrDevelopedIllnessWithin2Years'
          progress = 24
          break

        case 'DoesUserKnowWorkPlaceInjuryWorth':
        case 'DoesUserKnowWorkPlaceInjuryWorthVWOVersion':
        case 'DoesUserKnowWorkPlaceInjuryWorthVWOVersionV2':
          nextStep = 'InjuredOrDevelopedIllnessWithin2Years'
          progress = 24
          break

        case 'InjuredOrDevelopedIllnessWithin2YearsCROVersionV3': {
          nextStep = 'SelectDateV2'
          progress = 36
          break
        }

        case 'InjuredOrDevelopedIllnessWithin2Years': {
          if (rootState.tortData.injured_or_illness_last_two_years === 'no') {
            nextStep = 'DateOfBirth'
            progress = 36
            break
          }

          nextStep = 'SelectDateV2'
          progress = 36
          break
        }

        case 'DateOfBirth': {
          if (rootState.fields.age >= 53) {
            nextStep = 'OutOfWorkDueToDisability'
            progress = 36
            break
          }
          nextStep = 'SelectDateV2'
          progress = 48
          break
        }

        case 'OutOfWorkDueToDisability': {
          if (rootState.tortData.out_of_work_due_to_mental_conditions === 'yes') {
            nextStep = 'OutOfWorkAtLeastAYear'
            progress = 48
            break
          }
          nextStep = 'SelectDateV2'
          progress = 48
          break
        }

        case 'OutOfWorkAtLeastAYear': {
          if (rootState.tortData.expect_to_be_out_of_work_at_least_a_year === 'yes') {
            nextStep = 'Worked5OutOfTheLast10'
            progress = 55
            break
          }

          nextStep = 'SelectDateV2'
          progress = 48
          break
        }

        case 'Worked5OutOfTheLast10': {
          if (rootState.tortData.worked_5_of_last_10_years === 'yes') {
            nextStep = 'SocialSecurityDisabilityBenefits'
            progress = 60
            break
          }

          nextStep = 'SelectDateV2'
          progress = 48
          break
        }

        case 'SocialSecurityDisabilityBenefits': {
          if (rootState.tortData.received_disability_benefits === 'no') {
            nextStep = 'AttorneyHelpingYouWithCase'
            progress = 66
            break
          }

          nextStep = 'SelectDateV2'
          progress = 48
          break
        }

        case 'AttorneyHelpingYouWithCase': {
          if (rootState.tortData.has_attorney === 'no') {
            nextStep = 'TreatedByMedicalProfessional'
            progress = 72
            break
          }

          nextStep = 'SelectDateV2'
          progress = 48
          break
        }

        case 'TreatedByMedicalProfessional': {
          if (rootState.tortData.receiving_medical_treatment_for_disability === 'yes') {
            nextStep = 'DescribeYourIllness'
            progress = 78
            commit('SET_FIELD', {field: 'original_affid', value: rootState.fields.affid ? rootState.fields.affid : 1}, { root: true })
            dispatch('generateNewEFClickID', {
              affid: 588, // WC Downline
              oid: 60
            })
            commit('SET_FIELD', {field: 'oid', value: 60}, { root: true })
            commit('SET_FIELD', {field: 'affid', value: 588}, { root: true })
            break
          }

          nextStep = 'SelectDateV2'
          progress = 48
          break
        }

        case 'DescribeYourIllness':
          nextStep = 'EmailAndNameInformation'
          progress = 85
          break

        case 'SelectDateV2':
          nextStep = 'CaseDescriptionV2'
          progress = 38
          break

        case 'CaseDescriptionV2': {
          nextStep = 'PleaseDescribeYourInjuriesV3'
          progress = 44
          break
        }

        case 'PleaseDescribeYourInjuriesV3': {
          nextStep = 'DidYouSeeDoctorForInjuryAtWork'
          progress = 60
          break
        }

        case 'DidYouSeeDoctorForInjuryAtWork': {
          nextStep = 'HasLawyer'
          progress = 72
          break
        }

        case 'HasLawyer': {
          nextStep = 'EmailAndNameInformation'
          progress = 80
          break
        }

        case 'WhatWasTheCauseOfYourInjuryV2': {
          if (path === '/workers-comp-vw3' || path === '/workers-comp-vw3/') {
            nextStep = 'EmailAndNameInformationCROVersionV3'
            progress = 90
            break
          }
          nextStep = 'EmailAndNameInformation'
          progress = 90
          break
        }

        case 'EmailAndNameInformationCROVersionV3': {
          nextStep = 'ZipAndPhoneCROv3'
          progress = 96
          break
        }

        case 'EmailAndNameInformation': {
          nextStep = 'ZipAndPhone'
          progress = 96
          break
        }

        case 'ZipAndPhoneCROv3': {
          // check if user is disqualified to show disqualified component, or to redirect them to thank you page
          progress = 100
          commit('SET_FIELD', { field: 'qualified', value: getters.getQualified ? 'yes' : 'no' }, { root: true })
          dispatch('postData', {}, {root: true})
          return
        }

        case 'ZipAndPhone': {
          // check if user is disqualified to show disqualified component, or to redirect them to thank you page
          progress = 100
          commit('SET_FIELD', { field: 'qualified', value: getters.getQualified ? 'yes' : 'no' }, { root: true })
          dispatch('postData', {}, {root: true})
          return
        }
      }
      commit('SET_CURRENT_PROGRESS', progress, { root: true })
      commit('SET_CURRENT_STEP', nextStep, { root: true })
    },

    goToDisqualifyThankYouPage({rootState}) {
      const url = new URLSearchParams(location.search)
      if (Math.random() < 0.2 || url.has('force-coreg')) {
        const url = 'https://www.ikd92trk.com/cmp/34Z5MH/362QRQ/?'
            + `&sub1=${rootState.fields.affid}`
            + `&sub2=${rootState.fields.sub_id1}`
            + `&affid=86`
            + '&wc=true'
        window.open(url, '_blank');
      } else {
        const efTransactionId = rootState.fields.ef_transaction_id
        const subId = rootState.fields.sub_id1
        const affId = rootState.fields.affid || ''
        const url = `https://click.clktraker.com/aff_ad?campaign_id=8249&aff_id=10257&hostNameId=2951&aff_sub=WorkersPop&aff_click_id=${efTransactionId}&aff_sub2=${affId}&aff_sub3=${subId}`
        window.open( url, '_blank');
      }
    },

    loadMediaGravyWorkersCompScript({ rootState }) {
      if (rootState.fields.affid === '389') {
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1268728890425808');
        fbq('track', 'PageView');
      }
    },

    generateNewEFClickID({ commit, rootState }, { affid, oid }) {
      const urlParams = new URLSearchParams(location.search)
      window.EF.click({
        offer_id: oid,
        affiliate_id: affid,
        sub1: urlParams.get('sub1'),
        sub2: urlParams.get('sub2'),
        sub3: urlParams.get('sub3'),
        sub4: urlParams.get('sub4'),
        sub5: urlParams.get('sub5'),
      }).then(tid => {
        if (tid) {
          urlParams.set('_ef_transaction_id', tid)
          urlParams.set('affid', affid)
          urlParams.set('oid', oid)
          history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
        }
      })
    }
  }
}

export default workersCompV2
